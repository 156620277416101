import { defineComponent, h, readonly, ref } from 'vue'
import type { MetaDataType } from '@/models/MetaData'
import { createSSRApp } from 'vue'
import { renderToString } from '@vue/server-renderer'
import { MetaTags } from '@/components/MetaTags'

let metaData = {
  title: ref<string | undefined>(undefined),
  description: ref<string | undefined>(undefined),
  image: ref<string | undefined>(undefined),
  url: ref<string | undefined>(undefined),
  noIndex: ref<boolean | undefined>(undefined),
  language: ref<string | undefined>(undefined),
}

const metaDataFn = ref(() => metaData)

export const useMeta = () => {
  const renderMetaTags = () => {
    const App = defineComponent({
      setup() {
        return () => h(MetaTags, { metaDataFn: () => metaData, ssr: true })
      },
    })
    const app = createSSRApp(App)
    return renderToString(app)
  }

  const replaceTeleport = (
    currentTeleport: string | undefined,
    addTeleport: string
  ): string | undefined => {
    const startString = '<!--[--><!--meta-tags-start-->'
    const endString = '<!--meta-tags-end--><!--]-->'
    const anchorString = '<!--teleport anchor-->'
    if (!currentTeleport) {
      return addTeleport + anchorString
    }
    const startIndex = currentTeleport.indexOf(startString)
    const endIndex = currentTeleport.indexOf(endString)
    let newTeleport = ''
    if (startIndex === -1 || endIndex === -1) {
      newTeleport = addTeleport + currentTeleport
    } else {
      newTeleport =
        currentTeleport.slice(0, startIndex) +
        addTeleport +
        currentTeleport.slice(endIndex + endString.length)
    }
    if (!newTeleport.endsWith(anchorString)) {
      newTeleport += anchorString
    }
    return newTeleport
  }

  return {
    metaData: readonly(metaData),
    metaDataFn: readonly(metaDataFn),
    updateMetaData: (newMetaData: MetaDataType) => {
      metaData = { ...metaData, ...newMetaData }
      metaDataFn.value = () => metaData
    },
    renderMetaTags,
    replaceTeleport,
  }
}
